@import '../../assets/scss/variables';


.sidepanel-container {
    border-right    : $main-border solid $primary-color;
    max-width       : 20%;
    // border-bottom: $main-border solid $primary-color;

    .sidepanel-image {
        width: 100%;
    }

    .option-container {
        h1 {
            margin   : 20px 8px;
            font-size: $sectionTitleSize
        }
    }
}