@import '../../assets/scss/variables';


.button-container {
  letter-spacing  : 0.5px;
  line-height     : 50px;
  padding         : 0 35px 0 35px;
  font-size       : 15px;
  background-color: $primary-button;
  color           : white;
  text-transform  : uppercase;
  font-weight     : bolder;
  border          : none;
  border-radius   : 10px;
  cursor          : pointer;
  display         : flex;
  justify-content : center;
  

  &:hover {
    background-color: white;
    color           : $primary-button;
    border          : 1px solid $primary-button;
  }
}

