// @import '../../../assets/scss/variables';
@import '../../assets/scss/variables';

.auth-form-container {
    margin        : auto;
    display       : flex;
    flex-direction: column;
    max-width     : 550px;
    padding       : 10px;
    padding-top   : 0px;
    // border: 2px solid red;


    h2 {
        margin    : 5px 0px;
        color     : $primary-color;
        text-align: center;
    }

    span {
        color     : $primary-color;
        text-align: center;
    }


}

.auth-buttons-container {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    h3 {
        // background-color: red;
        margin-top   : 4px;
        padding-top  : 4px;
        margin-bottom: 2px;
        cursor       : pointer;

    }
}

@media screen and (max-width: 450px) {
    .auth-buttons-container {

        h3 {
            font-size: 16px;

        }
    }
}