@import '../../assets/scss/variables';


.mainpage-container {
    display: flex;
    height : 100vh;
    border        : $main-border solid $primary-color;

    .mainpage-sub-container {
        width: 100%;
    }
}