@import '../../assets/scss/variables';

.headerbar-container {
    height       : 80px;
    border-bottom: $main-border solid $primary-color;
    width        : 100%;
    display      : flex;
    align-items  : center;
    justify-content: space-between;
}

.headerbar-title {
    font-size     : $pageTitleSize;

    text-transform: uppercase;
    padding       : 0;
    margin        : 0;
}